// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-paris-secret-js": () => import("./../../../src/pages/a-paris-secret.js" /* webpackChunkName: "component---src-pages-a-paris-secret-js" */),
  "component---src-pages-an-italian-affair-js": () => import("./../../../src/pages/an-italian-affair.js" /* webpackChunkName: "component---src-pages-an-italian-affair-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shadows-over-the-spanish-sun-js": () => import("./../../../src/pages/shadows-over-the-spanish-sun.js" /* webpackChunkName: "component---src-pages-shadows-over-the-spanish-sun-js" */)
}

